<template>
  <div :style='backgroundEventStyle'>
    <img v-if='bannerImage' 
      :src='bannerImage' 
      class='w-full mb-2'
      @click='goToBannerLink' />
    <img 
      :src='promotionalImagePath'
      class='pointer-cursor'
      @click='goToLink' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PromotionEventContainer',
  components: {

  },
  props: {
    bannerImage: {
      type: String,
      default: '',
      required: false,
    },
    bannerImageClickLinkTo: {
      type: String,
      default: '',
      required: false,
    },
    promotionalImagePath: {
      type: String,
      default: '',
      required: false,
    },
    promotionalImageClickLinkTo: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundEventStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
  },
  methods: {
    goToLink () {
      window.open(this.promotionalImageClickLinkTo)
    },
    goToBannerLink () {
      window.open(this.bannerImageClickLinkTo, '_blank')
    },
  },
}
</script>

<style lang='scss' scoped>

</style>
