<template>
  <div class='relative flex flex-col lg:flex-row justify-start lg:justify-between items-start mt-4 lg:mt-8'>
    <div class='flex-shrink-0 flex-grow-0'>
      <h1 class='px-4 py-4 lg:py-8 text-2xl font-medium capitalize mb-4'>events</h1>
      <div class='w-screen lg:w-80 overflow-x-auto flex flex-row lg:flex-col justify-start'>
        <div v-for='(promotionNavigation, index) in promotionalEventsList'
          :key='`promotion-event-${promotionNavigation.name}`'
          class='promotion-navigation-tab px-2 lg:px-4 py-3 lg:py-6 cursor-pointer mb-2' style='min-width: 10rem;'
          :class='isSelectedPromotionalEvent(index)' @click='selectPromotion(index)'>
          <h2 class='text-normal lg:text-2xl pb-1' style='color: #380622;'>{{promotionNavigation.name}}</h2>
          <h3 class='text-sm lg:text-normal opacity-50'>{{promotionNavigation.dateString}}</h3>
        </div>
      </div>
    </div>
    <div class='flex-grow min-h-screen p-2 lg:p-8 rounded' style='background-color: var(--lightColor);'>
      <component 
        :is='selectedEventComponent' 
        :include-background-cover='false' 
        :banner-image='selectedEventBanner'
        :promotional-image-path='promotionalImagePath' 
        :promotional-image-click-link-to='promotionalImageClickLinkTo' />
    </div>
  </div>
</template>

<script>
import PromotionEventContainer from '@/components/khrs-virtual/PromotionEventContainer.vue'
import SocialMediaStyleImages from '@/components/social-media-style/SocialMediaStyleImages.vue'

export default {
  name: 'PromotionalEvents',
  components: {
    PromotionEventContainer,
    SocialMediaStyleImages,
  },
  data () {
    return {
      selectedPromotionIndex: 0,
      promotionalEventsList: [
        {
          name: 'KHRS 2022 Selfie Event',
          dateString: 'June 24 (Fri) - June 25 (Sat)',
          componentName: 'social-media-style-images',
          bannerImage: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/SelfieEvent.png',
        },
        {
          name: 'E-Booth Stamp Event',
          dateString: 'June 24 (Fri) - June 25 (Sat)',
          componentName: 'promotion-event-container',
          promotionalImagePath: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/E-StampEvent.png',
          promotionalImageClickLinkTo: '/exhibition-hall',
        },
        {
          name: 'Survey Event',
          dateString: 'June 24 (Fri) - July 3 (Sun)',
          componentName: 'promotion-event-container',
          promotionalImagePath: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/SurveyEvent.png',
          promotionalImageClickLinkTo: 'https://docs.google.com/forms/d/e/1FAIpQLScv-vJ0BAJCRDdvUj6Ij6cNF3J9si8NX4E4pi0P4ySN42vgvg/viewform',
        },
      ],
    }
  },
  computed: {
    selectedEventComponent () {
      return this.promotionalEventsList[this.selectedPromotionIndex].componentName
    },
    selectedEventBanner () {
      return this.promotionalEventsList[this.selectedPromotionIndex].bannerImage
    },
    promotionalImagePath () {
      return this.promotionalEventsList[this.selectedPromotionIndex].promotionalImagePath
    },
    promotionalImageClickLinkTo () {
      return this.promotionalEventsList[this.selectedPromotionIndex].promotionalImageClickLinkTo
    },
  },
  methods: {
    isSelectedPromotionalEvent (index) {
      return (this.selectedPromotionIndex === index) ? 'selected' : ''
    },
    selectPromotion (index) {
      this.selectedPromotionIndex = index
      this.$router.push({
        name: 'PromotionalEvents',
        query: {
          promotion: index
        },
      }).catch(() => {})
    },
  },
  mounted () {
    let promotionQuery = this.$route.query.promotion
    if (promotionQuery && (promotionQuery > 0 && promotionQuery < 3)) { // ignore if promotion is 0
      this.selectPromotion(parseInt(promotionQuery))
    }
  },
}
</script>

<style lang='scss' scoped>
  .promotion-navigation-tab {
    @apply border-t-2 border-l-0 border-transparent text-center;
    background-color: var(--lightestColor);
    h2 {
      @apply font-thin;
    }
  }

  .promotion-navigation-tab.selected {
    border-left-color: transparent;
    border-top-color: var(--inputColor);
    background-color: var(--lightColor);
    h2 {
      @apply font-normal;
    }
    h3 {
      @apply opacity-100;
    }
  }

  @media (min-width: 1024px) { 
    .promotion-navigation-tab {
      @apply border-l-4 border-t-0 border-transparent text-left;
    }

    .promotion-navigation-tab.selected {
      border-left-color: var(--inputColor);
      border-top-color: transparent;
    }
  }



</style>
